import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  Input,
  AfterContentChecked,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, catchError, combineLatest, filter, of } from 'rxjs';
import { constants } from 'src/app/core/constants/constants';
import { AlertType } from 'src/app/shared/components/app-alert/app-alert.component';
import { DocumentTypesApiService } from 'src/app/modules/doc-types/services/document-types-api.service';
import { PositionDto, PositionsApiService } from 'src/app/shared/services/positions-api.service';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { UserDto } from 'src/app/modules/login/services/user-api.service';
import { selectUser } from 'src/app/core/store/selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/state';
import { isPresent } from 'src/app/core/utils/isPresent';
import { fromQuestions } from '../../../store/selectors';
import { GetQuestionDto, QuestionDto } from '../../../models/questions.model';
import { QuestionsActions } from '../../../store/actions';
import { GetDocumentTypeDto } from 'src/app/modules/doc-types/models/doc-types.model';
import { LanguageDto } from 'src/app/shared/services/languages-api.service';
import { selectContentLanguages } from 'src/app/shared/store/selectors';
import { newDocTypeSuccessAddedForMultipleSelector } from 'src/app/core/utils/doc-types.utils';
import { DocTypesActions } from 'src/app/modules/doc-types/store/actions';

@UntilDestroy()
@Component({
  selector: 'app-edit-question-modal',
  templateUrl: './edit-question-modal.component.html',
  styleUrl: './edit-question-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditQuestionModalComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @Input() id!: string;
  public onClose: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup | undefined;
  public addDocTypeForm: FormGroup | undefined;
  public body: string | undefined;
  public title: string = this.translocoService.translate(`titles.editQuestion`);
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public alertTypes = AlertType;
  public constants = constants;

  public docTypeItems: any;
  public positionItems!: PositionDto[];

  public creatingNewDocType: boolean = false;

  public user$: Observable<UserDto | null> | undefined;
  public currentUser: UserDto | null | undefined;
  public isQuestionLoading$ = this.store$.select(fromQuestions.selectQuestionLoading);
  public isQuestionEditing$ = this.store$.select(fromQuestions.selectQuestionEditing);
  public question: GetQuestionDto | null | undefined;
  public contentLanguages: LanguageDto[] = [];

  constructor(
    private store$: Store<AppState>,
    private bsModalRef: BsModalRef,
    private elementRef: ElementRef,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly documentTypesApiService: DocumentTypesApiService,
    private readonly positionsApiService: PositionsApiService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngAfterContentChecked(): void {}

  ngAfterViewInit(): void {
    this.store$
      .select(fromQuestions.selectCloseEditingQuestionModal)
      .pipe(untilDestroyed(this))
      .subscribe((close) => {
        if (close) {
          this.bsModalRef.hide();
          this.store$.dispatch(QuestionsActions.clearEditQuestionCancelled());
        }
      });
  }

  public ngOnInit(): void {
    this.isQuestionLoading$.pipe(untilDestroyed(this)).subscribe((loading) => {
      if (loading === false) {
        this.initForm();
      }
    });
  }

  initForm(): void {
    combineLatest([
      this.store$.select(selectUser),
      this.store$.select(fromQuestions.selectQuestion).pipe(filter(isPresent)),
      this.store$.select(selectContentLanguages).pipe(filter(isPresent)),
      this.documentTypesApiService.getDocumentTypes$(),
      this.positionsApiService.getPositions$(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([user, question, contentLanguages, docTypes, postitions]) => {
        this.currentUser = user;
        this.contentLanguages = contentLanguages;
        this.question = question;
        this.docTypeItems = docTypes;
        this.positionItems = postitions;

        this.form = this.fb.group({
          questionText: [this.question?.text, { validators: [Validators.required] }],
          documentTypes: [this.question?.documentTypes, { validators: [Validators.required] }],
          positions: [this.question?.positions, { validators: [Validators.required] }],
          languageId: [this.question?.languageId, { validators: [Validators.required] }],
        });

        this.form
          ?.get('documentTypes')
          ?.patchValue(this.question?.documentTypes, { emitEvent: true });

        this.addDocTypeForm = this.fb.group({
          newDocumentType: ['', { validators: [Validators.required] }],
        });

        this.cdr.markForCheck();
      });
  }

  openNewDocTypeForm() {
    this.creatingNewDocType = true;
  }

  onConfirmNewDocType() {
    this.documentTypesApiService
      .createDocumentType$(this.addDocTypeForm?.get('newDocumentType')?.value)
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this.addDocTypeForm?.get('newDocumentType')?.setErrors({ docTypeNameAlreadyExist: true });
          const invalidControl = this.elementRef.nativeElement.querySelector('#newDocumentType');
          invalidControl?.focus();
          return of();
        }),
      )
      .subscribe((newDocType: GetDocumentTypeDto) => {
        this.documentTypesApiService
          .getDocumentTypes$()
          .pipe(untilDestroyed(this))
          .subscribe((docTypeItems) => {
            this.store$.dispatch(DocTypesActions.setDocTypes({ docTypes: docTypeItems }));

            newDocTypeSuccessAddedForMultipleSelector(
              newDocType,
              this.form,
              this.docTypeItems,
              this.onCancelNewDocType.bind(this),
            );
          });
      });
  }

  onCancelNewDocType() {
    this.addDocTypeForm?.reset();
    this.creatingNewDocType = false;
    this.cdr.markForCheck();
  }

  public onConfirm(): void {
    const text = this.form?.get('questionText')?.value;
    const documentTypesValue = this.form?.get('documentTypes')?.value;
    const documentTypes =
      typeof documentTypesValue === 'string'
        ? documentTypesValue.split(',').map((id: string) => +id)
        : documentTypesValue;
    const positionsValue = this.form?.get('positions')?.value;
    const positions =
      typeof positionsValue === 'string'
        ? positionsValue.split(',').map((id: string) => +id)
        : positionsValue;
    const languageId = this.form?.get('languageId')?.value;

    const model = {
      text,
      documentTypes,
      positions,
      languageId,
    } as QuestionDto;

    this.store$.dispatch(QuestionsActions.editQuestion({ model, id: this.id }));
  }

  public onCancel(): void {
    const pristine = !!this.form?.pristine;
    this.store$.dispatch(QuestionsActions.editQuestionCancel({ pristine }));
  }
}
